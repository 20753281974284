<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/lead`"
                title="Lead"
                :columns="columns"
                routerpath="/crm/addlead"
                :lead="true"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                sheetApi="export-to-excellead"
                viewComponent="LeadShow"
              >
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'transfer_from'">
                    {{ setTransferBy(props) }}
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
          <!-- <b-row v-if="role !== 'admin'">
            <b-col lg="4" md="6">
              <show-lead-view
                class="example"
                style="overflow-y: scroll; height: 10cm"
                :LeadData="LeadData"
                @LeadDataItem="getLeadDataItem"
              />
            </b-col>
            <b-col lg="8">
              <show-lead-details
                style="overflow-y: scroll; height: 12cm"
                class="example"
                :LeadDataItem="LeadDataItem"
              />
            </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "@/components/axios";

import showLeadView from "../../card/card-advance/showLeadView.vue";
import showLeadDetails from "../../card/card-advance/showLeadDetails.vue";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    GoodTableColumnSearch,
    showLeadView,
    showLeadDetails,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      users: "",
      LeadDataItem: "",
      projects: "",
      leadOption: [],
      LeadDataItem: "",
      LeadData: "",
      userData: JSON.parse(localStorage.getItem("userData")),
      columns: [
        {
          label: "Lead Source",
          field: "leadsource",
          responseValue: "name",
          type: "dropdown",
          url: "leadsource?pagenum=1&pagesize=5000",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Source",
          },
        },
        {
          label: "Sub Source",
          field: "subsource",
          responseValue: "name",
          type: "dropdown",
          url: "leadsource?pagenum=1&pagesize=5000",
          filterOptions: {
            enabled: true,
            placeholder: "Search Sub Source",
          },
        },
        {
          label: "Lead Stage",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Stage",
          },
        },
        {
          label: "Lead Category",
          field: "leadcategory_id",
          responseValue: "name",
          type: "dropdown",
          url: "all-leadcategory",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Stage",
          },
        },
        {
          label: "Name",
          field: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Lead Date",
          field: "leaddate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Lead Date",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Address",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Search Address",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "Pincode",
          field: "pincode",
          filterOptions: {
            enabled: true,
            placeholder: "Search Pincode",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },
        {
          label: "Assign By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Transfered From",
          field: "transfer_from",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Assign To",
          field: "assignto",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          responseValue: "name",
          type: "staticdropdown",
          url: "getUsers",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Lead" : "Add Lead"}`,
        getEditValue: `${baseApi}/getleadById`,
        submitRouterPath: "/crm/lead",
        usersDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/lead`,
        selectedItemId: null,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Lead Source",
            name: "leadsource",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            responseValue: "id",
            dropdownname: "leadsource",
            url: "leadsource",
            required: true,
            options: [],
          },
          {
            label: "Sub Source",
            name: "subsource",
            value: "",
            type: "hidden",
            changeFieldType: "dropdown",
            responseValue: "id",
            class: "",
            changeclass: "col-md-6",
            parentComponent: "",
            dropdownname: "subsource",
            url: "subsource",
            required: true,
            options: [],
          },
          {
            label: "Channel partner",
            name: "channelpartner",
            value: "",
            type: "hidden",
            changeFieldType: "dropdown",
            responseValue: "id",
            class: "",
            changeclass: "col-md-6",
            parentComponent: "Channel partner",
            dropdownname: "channelpartner",
            url: "channelpartner",
            required: true,
            options: [],
          },
          {
            label: "Franchise",
            name: "franchise",
            value: "",
            type: "hidden",
            changeFieldType: "dropdown",
            responseValue: "id",
            class: "",
            changeclass: "col-md-6",
            parentComponent: "Franchise",
            dropdownname: "franchise",
            url: "franchise",
            required: true,
            options: [],
          },
          {
            label: "Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Lead Date",
            name: "nextfollowupdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Enter Type",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Contact Person",
            name: "contactperson",
            value: "",
            type: "text",
            placeholder: "Enter Contact Person",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Mobile",
            name: "mobile",
            value: "",
            type: "number",
            placeholder: "Enter Mobile",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Email",
            name: "email",
            value: "",
            type: "email",
            placeholder: "Enter Mobile",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Address",
            name: "address",
            value: "",
            type: "text",
            placeholder: "Enter Address",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Area",
            name: "area",
            value: "",
            type: "text",
            placeholder: "Enter Area",
            class: "col-md-6",
            required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-6",
            required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Pin Code",
            name: "pincode",
            value: "",
            type: "text",
            placeholder: "Enter Pincode",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Remark",
            name: "remarks",
            value: "",
            type: "text",
            placeholder: "Enter Remarks",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Assign to",
            name: "assignto",
            value: "",
            type: "dropdown",
            responseValue: "id",
            class: "col-md-6",
            dropdownname: "assignto",
            url: "getUsers",
            required: true,
            options: [],
          },
        ],
      },
      assignToFormOptions: {
        formTitle: `${this.$route.params.id ? "Edit Lead Source" : "Add Lead Source"}`,
        submitRouterPath: "/master/leadsource",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/leadsource`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Channel Partner",
            name: "channelpartner",
            value: "",
            type: "dropdown",
            class: "col-md-12",
            dropdownname: "channelpartner",
            url: "channelpartner",
            required: false,
            options: [],
          },
          {
            label: "Employee",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-12",
            dropdownname: "employee",
            url: "getUsers",
            required: false,
            options: [],
          },
        ],
      },
      edit: "",
      add: "",
      canDelete: "",
      role: "",
    };
  },
  computed: {
    setTransferBy() {
      return (props) => {
        let arr = props.row.transfer_from.map((item) => {
          return item.transfer_from_user ? item.transfer_from_user.fullname : "";
        });
        arr = arr.filter((item) => (item ? true : false));
        return arr.join(", ");
      };
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const loginData = JSON.parse(localStorage.getItem("userData"));
    this.role = loginData.role;
    let type = loginData.type;

    if (this.role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Lead") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
      // this.getLeadData();
    }
  },
  methods: {
    init() {
      this.getProject();
      this.getUsers();
      this.getLeadStage();
    },
    async getLeadData() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getleadbyenterby`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.LeadData = response.data.data;
          this.LeadDataItem = response.data.data[0];
          if (JSON.stringify(this.LeadDataItem.followupData) !== "[]") {
            this.init();
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUsers() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.users = response.data.data;
          this.LeadDataItem.followupData.map((data) => {
            this.users.map((item) => {
              if (data.enterby == item.id) {
                data.enterby = item.name;
              }
            });
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.projects = response.data.data;
        this.LeadDataItem.followupData.map((data) => {
          this.projects.map((item) => {
            if (data.project_id == item.id) {
              data.project_id = item.projectname;
            }
          });
        });
      });
    },
    async getLeadStage() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadstage`,
      };

      await axios(requestOptionsUsers)
        .then((response) => {
          this.leadOption = response.data.data.data;
          this.LeadDataItem.followupData.map((data) => {
            this.leadOption.map((item) => {
              if (data.status == item.id) {
                data.status = item.name;
              }
            });
          });
          this.$forceUpdate();
        })
        .catch((error) => console.log(error, "error"));
    },
    getLeadDataItem(item) {
      this.LeadDataItem = item;
      if (JSON.stringify(this.LeadDataItem.followupData) !== "[]") {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
